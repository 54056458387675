<template>
  <div class="">
    <center-title title="采购计划列表"></center-title>

    <router-link to="/selectmygoodslist" class="el-button whtie submit">产品浏览</router-link>
    <router-link to="/shoppingPlan" class="el-button whtie submit" style="margin-bottom: 2%;">采购清单</router-link>
    <router-link to="/shoppingPlanList" class="el-button whtie submit" style="margin-bottom: 2%;">采购计划列表</router-link>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-input v-model="form.plan_name" placeholder="方案名称">
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.status" placeholder="采购清单状态">
              <el-option label="全部" value="0"></el-option>
              <el-option label="未审核" value="0"></el-option>
              <el-option label="已审核" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)">筛选</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="settlementList">
      <el-table-column align="center" prop="plan_name" style="width: 30%;" label="方案名称">
      </el-table-column>


      <el-table-column align="center" prop="in_time" label="创建时间">
      </el-table-column>

      <el-table-column align="center" prop="status_texta" content="" label="部门审核">

      </el-table-column>

      <el-table-column align="center" prop="status_textb" content="" label="后勤审核">

      </el-table-column>

      <el-table-column align="center" prop="status" label="操作">
        <template slot-scope="scope">
          <!-- <div class="primary cursor mb5" @click="(dialogVisible = true), (auditBox.id = scope.row.id)"
            v-if="scope.row.status === 0">
            审核
          </div>
          <div class="warn mb5" v-else>已审核</div> -->
          <!-- <button type="primary" class="status-btn white row-center" @click="
            (auditBox.plan_id = scope.row.id),
            (auditBox.type = 1),
            (dialogVisible = true)
            " v-if="scope.row.status_a == '0' && scope.row.status_b == '0'">
            待审核(部门审核)
          </button>
          <button type="primary" class="status-btn white row-center" @click="
            (auditBox.plan_id = scope.row.id),
            (auditBox.type = 2),
            (dialogVisible = true)
            " v-if="scope.row.status_a == '1' && scope.row.status_b == '0'">
            
            待审核(后勤审核)
          </button> -->
          <!-- <div class="primary cursor mb5"> <router-link :to="`/examine_car?id=${scope.row.id}`">审核</router-link></div> -->



          <button type="primary" class="status-btn white row-center"
            @click="(dialogVisible = true), (auditBox.id = scope.row.id)"
            v-if="scope.row.status_a == '0' && scope.row.status_b == '0'">
            <router-link :to="`/examine_car?id=${scope.row.id}&type=1`"> 待审核(部门)</router-link>
          </button>

          <button type="primary" class="status-btn white row-center"
            @click="(dialogVisible = true), (auditBox.id = scope.row.id)"
            v-if="scope.row.status_a == '1' && scope.row.status_b == '0'">
            <router-link :to="`/examine_car?id=${scope.row.id}&type=2`"> 待审核(后勤)</router-link>
          </button>

          <div class="primary cursor mb5" v-if="scope.row.status_a == '1' && scope.row.status_b == '1'">
            已审核
          </div>



          <div class="primary cursor mb5"> <router-link :to="`/plancar?id=${scope.row.id}`">查看明细</router-link></div>

        </template>
      </el-table-column>
    </el-table>


    <div class="row-center pagination mt30">
      <make-pagination @resetList="resetList" :orderObject="orderObject"></make-pagination>
    </div>
    <el-dialog title="订单审核" :visible.sync="dialogVisible" width="30%">
      <el-radio-group v-model="auditBox.status" class="mb20">
        <el-radio :label="1" :value="1">通过审核</el-radio>
        <el-radio :label="2" :value="2">驳回审核</el-radio>
      </el-radio-group>
      <el-input v-if="auditBox.status == 2" type="textarea" :rows="4" placeholder="请输入驳回内容（驳回时必填）"
        v-model="auditBox.reason" resize="none">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setAudit()">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      page_no: 1,
      page_size: 5,
      settlementList: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        plan_name: "",
        status: "",
        date: [],
        start_time: "",
        end_time: "",
      },
      orderObject: {},
      auditBox: {
        id: "",
        type: 1,
        reject_reason: "",
      },
      uploadPay: "",
    };
  },
  computed: {},
  watch: {},
  methods: {

    async setAudit() {
      let { auditBox } = this;
      if (auditBox.status == 2 && auditBox.reason == "") {
        return this.$message({
          message: "请填写驳回内容",
          type: "warning",
        });
      }
      let api =
        auditBox.type == 1
          ? this.$api.setFinanceExamineApi
          : this.$api.setManagerExamineApi;
      let { code, msg } = await api(auditBox);
      if (code == 1) {
        this.$message({
          message: msg,
          type: "success",
        });
        this.auditBox.reason = "";
        this.dialogVisible = false;
      }
      this.resetList();
    },


    onSubmit() { },
    resetList(e) {
      this.page_no = e;
      this.getProcurePlanList();
    },
    /**
     * @description 获取采购计划列表
     * @returns void
     */
    getProcurePlanList() {
      this.$api
        .getProcurePlanListApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
          plan_name: this.form.plan_name,
        })
        .then((res) => {
          this.settlementList = res.data.list;
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
        });
    },

    resetList(e) {
      this.page_no = e;
      this.getProcurePlanList();
    },
  },
  created() {
    this.getProcurePlanList();
  },
  mounted() { },
  beforeDestroy() { },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}

.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
